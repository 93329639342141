import moment from "moment";
import vueDatepicker from "vuejs-datepicker";
import { pl } from "vuejs-datepicker/dist/locale";
import { DATE_FORMAT } from "@/constants";

export default {
  components: {
    vueDatepicker,
  },
  props: {
    name: String,
    label: {
      type: String,
      required: false,
    },
    value: [String, Date],
    placeholder: {
      type: String,
      default: "dd/mm/rrrr",
    },
    theme: {
      type: String,
      default: "",
    },
    view: {
      type: String,
      default: "day",
    },
    disabledPicker: {
      type: Boolean,
      default: false,
    },
    openDate: {},
    disabledDates: {},
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pl,
    };
  },
  computed: {
    datepickerModifier() {
      return {
        datepicker: true,
        "datepicker--has-label": !!this.label,
        [`datepicker--${this.theme}`]: this.theme,
      };
    },
    datepickerBoxModifier() {
      return {
        datepicker__box: true,
        "datepicker__box--is-filled": this.value,
        [`datepicker__box--${this.theme}`]: this.theme,
      };
    },
  },
  methods: {
    updateValue(e) {
      const value = moment(e).format(DATE_FORMAT);
      this.$emit("input", value);
    },
    click() {
      if (this.disabledPicker) {
        this.$emit("click");
      }
    },
  },
};
