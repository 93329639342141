import { mapActions, mapState } from "vuex";
import flowMixin from "@/mixins/flow";
import { loadScript } from "@/helpers/utils";
import { MODALS } from "@/store/modules/modal";
import ls, { KEYS } from "@/helpers/localStorage";
import config from "@/config";
import { getCreatorSteps } from "@/helpers/creatorV3/utils";
import tagManager from "@/services/gtm";
import appSimpleHeaderV3 from "@/components/v3/app-simple-header-v3/app-simple-header-v3.vue";
import creatorV3Footer from "./components/creator-v3-footer/creator-v3-footer.vue";

export default {
  mixins: [flowMixin],
  props: {
    sign: {
      type: String,
      required: false,
    },
    widgetId: {
      type: String,
      required: false,
    },
  },
  components: {
    appSimpleHeaderV3,
    creatorV3Footer,
  },
  data() {
    return {
      show: false,
      submit: false,
    };
  },
  computed: {
    ...mapState("creatorV3", ["offer", "indexes"]),
    isInEditMode() {
      return this.$route.name === "creator-edit";
    },
    isInPreviewMode() {
      return this.$route.name === "creator-overview";
    },
    isFromInspiration() {
      return this.$route.name === "creator-from-inspiration";
    },
    steps() {
      return getCreatorSteps();
    },
    isValid() {
      if (this.isInPreviewMode && this.isLastStep) {
        return false;
      }
      return this.currentStepIsValid && !this.submit;
    },
    isLastStep() {
      return this.indexes.current === this.indexes.total - 1;
    },
  },
  methods: {
    ...mapActions("creatorV3", [
      "clearOffer",
      "updateIndexes",
      "markStepOverviewNavigationHintAsDisplayed",
      "getCreatorDataFromInspiration",
      "getCreatorDataFromWidget",
      "getCreatorDataFromOffer",
      "setOffer",
      "reassignRoomsInPeopleToDefaultsInAllLocations",
      "submitOffer",
    ]),
    ...mapActions("modal", ["setCurrentModal"]),
    updateIndexesToCurrentState(data = {}) {
      this.updateIndexes({
        current: this.currentStepIndex,
        total: this.steps.length,
        ...data,
      });
    },
    onNext() {
      const previous = this.currentStep.name;
      const completed = Math.max(this.currentStepIndex, this.indexes.completed);
      this.onStepNext().then((onStepNextResult) => {
        if (onStepNextResult?.preventNavigation) {
          return;
        }
        tagManager.trackCreatorStep({
          name: this.currentStep.name,
          index: this.currentStepIndex,
          previous,
        });
        this.updateIndexesToCurrentState({
          completed,
        });
        this.markStepOverviewNavigationHintAsDisplayed(false); // so it shows when im on other step
      });
    },
    onPrevious() {
      const previous = this.currentStep.name;
      this.onStepPrevious();
      tagManager.trackCreatorStep({
        name: this.currentStep.name,
        index: this.currentStepIndex,
        previous,
      });
      this.updateIndexesToCurrentState();
      this.markStepOverviewNavigationHintAsDisplayed(false); // so it shows when im on other step
    },
    onStep(index) {
      const previous = this.currentStep.name;
      this.currentStepIndex = index;
      tagManager.trackCreatorStep({
        name: this.currentStep.name,
        index: this.currentStepIndex,
        previous,
      });
      this.updateIndexesToCurrentState();
    },
    showOnboardingIfEligible() {
      const show = !!this.widgetId || !ls.get(KEYS.SHOW_ONBOARDING, false);
      if (!show) return;

      this.setCurrentModal({
        name: MODALS.ONBOARDING,
      });
    },
    initializeForm({ reassignPeople = true } = {}) {
      tagManager.trackCreatorStep({
        name: this.currentStep.name,
        index: this.currentStepIndex,
        previous: null,
      });
      if (reassignPeople) {
        this.reassignRoomsInPeopleToDefaultsInAllLocations();
      }
      this.show = true;
    },
    onLastStepNext() {
      if (this.isInPreviewMode) {
        alert("Podgląd zapytania - brak możliwości przesłania formularza");
        return false;
      }

      if (this.submit) return true;
      this.submit = true;

      loadScript(config.captchaUrl, "grecaptcha").then((grecaptcha) => {
        grecaptcha.ready(() => {
          grecaptcha
            .execute(config.captchaSiteKey, { action: "creator" })
            .then((token) => {
              this.submitOffer({
                offer_data: this.offer,
                inspiration_id: this.inspirationId,
                widget_id: this.widgetId,
                captcha_token: token,
                is_edited: this.isInEditMode,
              })
                .then(() => {
                  this.$router.push({ name: "confirmation" });
                  tagManager.trackCreatorSubmissionSuccess();
                })
                .catch(() => {
                  this.submit = false;
                  tagManager.trackCreatorSubmissionError();
                });
              return true;
            });
        });
      });
    },
  },
  created() {
    document.body.classList.add("recaptcha-visible");
    loadScript(config.captchaUrl, "grecaptcha");
    this.updateIndexesToCurrentState();
    this.showOnboardingIfEligible();

    if (this.sign && this.isFromInspiration) {
      this.getCreatorDataFromInspiration(this.sign)
        .then((data) => {
          this.setOffer(data.creator);
          this.inspirationId = data.inspiration_id;
          this.initializeForm();
        })
        .catch(() => {
          this.initializeForm();
        });
    } else if (this.sign) {
      this.getCreatorDataFromOffer(this.sign)
        .then((data) => {
          this.setOffer(data.creator);
          // so we load proper people to rooms
          this.initializeForm({ reassignPeople: false });
        })
        .catch(() => {
          this.initializeForm();
        });
    } else if (this.widgetId) {
      this.getCreatorDataFromWidget(this.widgetId)
        .then((data) => {
          this.setOffer(data.creator);
          this.initializeForm();
        })
        .catch(() => {
          this.initializeForm();
        });
    } else {
      this.initializeForm();
    }
  },
  beforeDestroy() {
    document.body.classList.remove("recaptcha-visible");
    this.clearOffer();
  },
};
