import { SEGMENTS_MULTIPLE, SEGMENTS } from "@/constants";
import { get, uniq, isEmpty } from "lodash";
import store from "@/store";

export const stripTags = (string) =>
  (string || "").replace(/(<([^>]+)>)/gi, "");

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);
export const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
export const getNightSuffixFromStr = (input) => {
  const chunks = (input || "").replaceAll(" ", "").trim().split("-");
  const nightsText = chunks.length > 1 ? chunks[1] : chunks[0];
  const nightsNum = parseInt(nightsText, 10);
  const isNightNumGreat =
    nightsNum > 20 &&
    (nightsNum.toString().endsWith("2") ||
      nightsNum.toString().endsWith("3") ||
      nightsNum.toString().endsWith("4"));
  if (nightsNum === 1) {
    return "noc";
  } else if (nightsNum <= 4 || isNightNumGreat) {
    return "noce";
  }
  return "nocy";
};
export const groupByKeepOrder = (arr, prop) => {
  const newArr = [];
  const wrapObj = {};

  arr.forEach((item) => {
    let propName = item[prop];
    if (propName) {
      propName = propName.toString();
      if (!wrapObj[propName]) {
        wrapObj[propName] = [];
        newArr.push(wrapObj[propName]);
      }
      wrapObj[propName].push(item);
    }
  });

  return newArr;
};
export const planSectionTitle = ({ segments, full = false }) => {
  const kind = segments[0].kind;
  if (full && kind === SEGMENTS.HOTEL) {
    const metaLocations = segments.map((i) =>
      get(i, "meta.location_short_name", "")
    );
    const locations = uniq(metaLocations.filter((v) => !!v));
    const suffix = locations.join("/");
    return `${segments[0].kind_display} ${suffix}`;
  }
  if (full && kind === SEGMENTS.FLIGHT) {
    const titles = [];
    const segmentsWithMeta = segments.filter((i) => !isEmpty(i.meta));
    segmentsWithMeta.forEach((segment) => {
      const { data } = segment.meta;
      const { isMultiCity, slices } = data || {};
      const isOneWay = slices.length === 1;
      const isTwoWay = !isOneWay && !isMultiCity;

      let title = "";
      if (isOneWay) {
        title = `${slices[0].originIata}-${slices[0].destinationIata}`;
      } else if (isTwoWay) {
        title = `${slices[0].originIata}-${slices[0].destinationIata}-${slices[0].originIata}`;
      } else if (isMultiCity) {
        title = slices
          .map((i) => `${i.originIata}-${i.destinationIata}`)
          .join("/");
      }
      titles.push(title);
    });
    const uniqueFlightOptions = uniq(titles).join(" lub ");
    return `${segments[0].kind_display} ${uniqueFlightOptions}`;
  }
  const isMultiple = SEGMENTS_MULTIPLE.includes(kind);
  return isMultiple ? segments[0].kind_display : stripTags(segments[0].title);
};

const loadedScripts = {};
export const loadScript = (url, windowVariable) => {
  if (!loadedScripts[url]) {
    store.dispatch("app/incrLoaderCounter");
    loadedScripts[url] = new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.addEventListener(
        "load",
        () => {
          store.dispatch("app/decrLoaderCounter");
          resolve(windowVariable ? get(window, windowVariable) : undefined);
        },
        false
      );
      script.addEventListener(
        "error",
        (e) => {
          store.dispatch("app/decrLoaderCounter");
          reject(e);
        },
        false
      );
      document.body.appendChild(script);
    });
  }
  return loadedScripts[url];
};

export const preloadPlanImages = ({ plan }) => {
  if (!plan) return;
  const imagesToPreload = plan.segments.reduce((acc, currVal) => {
    if (!currVal.images || !currVal.images.length) {
      return acc;
    }
    // preload only 2 first images per segment
    acc.push(currVal.images[0]);
    if (currVal.images[1]) {
      acc.push(currVal.images[1]);
    }
    return acc;
  }, []);
  for (const image of imagesToPreload) {
    const imageEl = new Image();
    imageEl.src = image;
  }
};

export const uuidv4 = () =>
  "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );

export const getBedroomsSuffix = (number) => {
  const isNumGreat =
    number > 20 &&
    (number.toString().endsWith("2") ||
      number.toString().endsWith("3") ||
      number.toString().endsWith("4"));
  if (number === 1) {
    return "Sypialnia";
  } else if (number <= 4 || isNumGreat) {
    return "Sypialnie";
  }
  return "Sypialni";
};

export const isPeselValid = (value) => {
  const reg = /^\d{11}$/;
  if (!reg.test(value)) {
    return false;
  }

  const dig = ("" + value).split("");
  let control =
    (1 * parseInt(dig[0]) +
      3 * parseInt(dig[1]) +
      7 * parseInt(dig[2]) +
      9 * parseInt(dig[3]) +
      1 * parseInt(dig[4]) +
      3 * parseInt(dig[5]) +
      7 * parseInt(dig[6]) +
      9 * parseInt(dig[7]) +
      1 * parseInt(dig[8]) +
      3 * parseInt(dig[9])) %
    10;

  if (control === 0) {
    control = 10;
  }
  control = 10 - control;
  return parseInt(dig[10]) === control;
};
